import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledIcon = styled.span`
  // we need to override the 3rd party css with this one
  // this helps us avoid the use of !important
  &.material-symbols-outlined[role='presentation'] {
    font-size: ${(props) => props.size}px;
    ${({ color }) => (color ? `color: ${color};` : '')}
  }
`;

/**
 * A wrapper for materialized icons (https://bit.ly/3CbVHvl).
 */
const Icon = ({ name, size, color }) => (
  <StyledIcon
    className="material-symbols-outlined"
    size={size}
    color={color}
    role="presentation"
  >
    {name}
  </StyledIcon>
);

Icon.defaultProps = {
  size: 13,
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
};

export default Icon;
