import React, { useCallback, useEffect, useState } from 'react';
import useVisibility from '../../../lib/use_visibility';
import { Modal as ModalComponent, ModalPropTypes } from './modal';

export const useModal = (initialVisibilityState = false) => {
  const {
    shown,
    show: open,
    hide,
    toggleVisibility: toggle,
  } = useVisibility(null, initialVisibilityState);
  const [overflow, setOverflow] = useState();
  const close = useCallback(hide, []);
  useEffect(() => {
    if (shown) {
      setOverflow(document.documentElement.style.overflow);
      document.documentElement.style.overflow = 'hidden';
    } else {
      // in case initial overflow is `undefined`, it would not override the
      // 'hidden' (or any other) overflow value so we explicitly set it to
      // an empty string if the initial overflow is falsy
      document.documentElement.style.overflow = overflow || '';
    }
    return () => {
      if (shown) {
        document.documentElement.style.overflow = overflow || '';
      }
    };
  }, [shown]); //eslint-disable-line react-hooks/exhaustive-deps

  const Modal = useCallback(
    (modalProps) => {
      return <ModalComponent shown={shown} close={close} {...modalProps} />;
    },
    [shown]
  );

  Modal.propTypes = ModalPropTypes;
  return {
    shown,
    toggle,
    open,
    close,
    Modal,
  };
};
