/**
 * Common javascript functions.
 * Please don't let this file get too big.
 */

/**
 * Convert from camelCase to kebab-case.
 */
export const kebabise = (str) =>
  str.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);

/**
 * Remove whitespace from string.
 */
export const replaceWhitespace = (str, replaceWith = '_') =>
  str.replace(/\s+/g, replaceWith).replace(/[^A-Za-z_-]/, '');

export const removeEmptyKeys = (values) => {
  const normalizedValues = {};
  Object.keys(values).forEach((key) => {
    if (values[key] || values[key] === 0 || values[key] === false) {
      normalizedValues[key] = values[key];
    }
  });
  return normalizedValues;
};

const DOMAIN_SEGMENT_PATTERN = '[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?';
export const isURLValid = (str) => {
  // TLD of a domain name can be up to 63 characters (https://tools.ietf.org/html/rfc1034)
  const urlPattern = new RegExp(
    `^(http|https)://` +
      `${DOMAIN_SEGMENT_PATTERN}` +
      `(?:\\.${DOMAIN_SEGMENT_PATTERN})*` +
      `\\.[a-z]{2,63}(?:(:[0-9]{1,5})?(/|#|\\?).*)?$`,
    'i'
  );
  return urlPattern.test(str);
};

export const findItemByAttr = (array, value, attribute = 'id') =>
  array.find((el) => el[attribute] === value);

// a polyfill for window.crypto.randomUUID function based on
// https://stackoverflow.com/a/2117523/7562654
export const randomUUID = () => {
  return (
    window?.crypto?.randomUUID?.() ||
    '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) =>
      (
        +c ^
        (window.crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
      ).toString(16)
    )
  );
};

export const toggle = (item, list = []) =>
  list.includes(item)
    ? list.filter((addedId) => addedId != item)
    : [...list, item];

export const stringToCamelCase = (str) => {
  return str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
};

export const camelize = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(camelize);
  } else if (obj && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const camelCaseKey = stringToCamelCase(key);
      acc[camelCaseKey] = camelize(obj[key]); // recursively handle nested objects
      return acc;
    }, {});
  }
  return obj; // return the value if is not an object
};

// Helper function to convert a string from camelCase to snake_case
const camelToSnakeCase = (str) => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

export const serpentize = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(serpentize);
  } else if (obj && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const snakeCaseKey = camelToSnakeCase(key);
      acc[snakeCaseKey] = serpentize(obj[key]); // recursively handle nested objects
      return acc;
    }, {});
  }
  return obj;
};

export const removeSearchParam = (searchParamName) => {
  // get the current URL parameters
  const searchParams = new URLSearchParams(window.location.search);

  // remove the tabs parameter
  searchParams.delete(searchParamName);

  const newSearch = searchParams.toString();

  // create a new URL without the specified parameter
  const newUrl = `${window.location.pathname}${
    newSearch ? `?${newSearch}` : ''
  }${window.location.hash}`;

  // use the history to avoid refresh
  window.history.replaceState({}, document.title, newUrl);
};

export const isNumber = (value) => typeof value === 'number';
