import { useState, useEffect } from 'react';

/**
 * Toggles visibility of a component after calling a `toggleVisibility` function.
 *
 *     const { shown, toggleVisibility, show, hide } = useVisibility(parentRef, initialVisibilityState, closeAllBeforeShow);
 *
 *     <button onClick={ toggleVisibility }>Toggle</button>
 *     { shown && <>Hidden by default</> }
 *
 * If the parent reference is given (an instance of the `useRef` hook), a click outside of the
 * parent component will hide the component.
 */
const useVisibility = (
  parentRef,
  initialVisibilityState = false,
  closeAllBeforeShow = false,
  rootElSelector
) => {
  const [shown, setShown] = useState(initialVisibilityState);
  const toggleVisibility = (event) => {
    shown ? hide(event) : show(event);
  };

  const show = (event) => {
    closeAllBeforeShow && document.body.click();
    handleEvent(event);
    setShown(true);
  };
  const hide = (event) => {
    handleEvent(event);
    setShown(false);
  };

  // if we use this hook inside of a modal or any other element that prevents
  // the events to reach the body element we should declare it as the root
  // element, in order to handle closeOnClickOutside correctly
  const rootEl = document.querySelector(rootElSelector) || document.body;

  useEffect(() => {
    if (!shown || !parentRef) return;

    const closeOnClickOutside = (event) => {
      // this method gets called 2 times after clicking on the label of a checkbox (the first event
      // brings the label as a target, the second one brings the checkbox). When it is called second
      // time, `event.target` points to the previous version of the checkbox which was removed after
      // re-rendering. So, to keep the dropdown open, events on checkboxes are ignored
      if (
        parentRef.current &&
        event.target.type != 'checkbox' &&
        !parentRef.current.contains(event.target)
      )
        setShown(false);
    };

    rootEl.addEventListener('click', closeOnClickOutside);

    return () => rootEl.removeEventListener('click', closeOnClickOutside);
  }, [shown, parentRef]);

  return { shown, toggleVisibility, show, hide };
};

const handleEvent = (event) => {
  // we have to stop propagation to prevent event from bubbling
  // outside the clicked element triggering closeOnClickOutside
  if (event) {
    event.stopPropagation && event.stopPropagation();
  }
};
export default useVisibility;
