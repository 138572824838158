import styled from 'styled-components';
import { propsToAttributes, withCustomProps } from './utils';

export const Flex = withCustomProps(styled.div`
  display: flex;
  ${propsToAttributes}
`);

export const ButtonsWrapper = styled(Flex)`
  display: flex;
  justify-content: end;
  gap: 8px;
`;
